import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "D:/a/1/s/kope-docs-user/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`Review, compare, and save outcomes to identify the perfect solution for your project.`}</p>
    </PageDescription>
    <AnchorLinks mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Results</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Viewing Results</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Comparing Results</AnchorLink>
    </AnchorLinks>
    <h3>{`Results`}</h3>
    <p>{`As you test configurations with the Configurator and explore possibilities using Optimize, any outcomes that align with your specific project requirements can be saved for future reference. These saved results can be reviewed and compared to help you identify the optimal solution for your project.`}</p>
    <p>{`To access your saved results, simply navigate to the Saved Results screen by clicking the `}<inlineCode parentName="p">{`Results toolbar button`}</inlineCode>{` within your selected project.`}</p>
    <h3>{`Viewing Results`}</h3>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/getting-started/32_results-toolbar-button.png",
      "alt": "32_results-toolbar-button.png"
    }}></img>
    <p>{`Selecting a saved result from the list opens the Result Summary, where you can access the initial information used to create the result and examine the metrics and deliverables associated with that specific outcome.`}</p>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/getting-started/33_results-saved-result-overview.png",
      "alt": "33_results-saved-result-overview.png"
    }}></img>
    <p>{`Within the Metrics tab, all crucial metrics related to the saved result are displayed. For reporting purposes, both the Tables and Charts are customizable, allowing you to prioritize the information that is most relevant to your needs. `}</p>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/getting-started/34_results-metrics-overview.png",
      "alt": "34_results-metrics-overview.png"
    }}></img>
    <h3>{`Comparing Results`}</h3>
    <p>{`Once you have successfully created and saved multiple Configurator results, you can utilize the Compare functionality within KOPE to conduct a side-by-side analysis of these results. On the Saved Results screen, choose the results you want to compare and click the `}<inlineCode parentName="p">{`Compare Results`}</inlineCode>{` button.`}</p>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/getting-started/35_results-compare-button.png",
      "alt": "35_results-compare-button.png"
    }}></img>
    <p>{`This action opens the Results Comparison view, enabling you to compare the result data in a convenient side-by-side format. Use the Fields table on the left-hand side to show or hide specific tables as needed. `}</p>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/getting-started/36_results-compare-overview.png",
      "alt": "36_results-compare-overview.png"
    }}></img>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      